import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosIntance";

export const dashboardCount = createAsyncThunk("dashboardCount", async () => {
  try {
    const { data } = await axiosInstance.get("/DashboardCountAdmin");
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const showAllUsers = createAsyncThunk("showAllUsers", async () => {
  try {
    const { data } = await axiosInstance.get("/showAllUsersToAdmin");
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const showUserById = createAsyncThunk("showUserById", async (body) => {
  try {
    const { data } = await axiosInstance.post("/showUsersByGuidToAdmin", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});

export const showAllplan = createAsyncThunk("showAllplan", async () => {
  try {
    const { data } = await axiosInstance.get("/showAllplantoAdmin");
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const getUploadImagesAdmin = createAsyncThunk(
  "getUploadImagesAdmin",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/getUploadImagesAdmin", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);

export const changePlanStatus = createAsyncThunk(
  "changePlanStatus",
  async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/changePlanStatusByAdmin",
        body
      );
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const showAllWithdraw = createAsyncThunk(
  "showAllWithdraw",
  async (body) => {
    try {
      const { data } = await axiosInstance.get("/showAllwithdrawltoAdmin");
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const updateWallet = createAsyncThunk("updateWallet", async (body) => {
  try {
    const { data } = await axiosInstance.post(body.url, body.data);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
export const AddDividentByAdmin = createAsyncThunk(
  "AddDividentByAdmin",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/AddDividentByAdmin", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const changeWithdrawStatus = createAsyncThunk(
  "changeWithdrawStatus",
  async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/changeWithdrawStatusByAdmin",
        body
      );
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const showUserReferals = createAsyncThunk(
  "showUserReferals",
  async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/showMyRefralsbyUserIdAdmin",
        body
      );
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);

export const showWithdrawHistory = createAsyncThunk(
  "showWithdrawHistory",
  async (body) => {
    try {
      const { data } = await axiosInstance.post(
        "/showWithdrawHistoryAdmin",
        body
      );
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const showWalletHistory = createAsyncThunk(
  "showWalletHistory",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/showWalletHistory", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const AddPriceToNftByAdmin = createAsyncThunk(
  "AddPriceToNftByAdmin",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/AddPriceToNftByAdmin", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const editPriceToNftByAdmin = createAsyncThunk(
  "editPriceToNftByAdmin",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/editPriceToNftByAdmin", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);
export const deleteNFTpriceAdmin = createAsyncThunk(
  "deleteNFTpriceAdmin",
  async (body) => {
    try {
      const { data } = await axiosInstance.post("/deleteNFTpriceAdmin", body);
      return { data };
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  }
);

const UserSlice = createSlice({
  name: "UserSlice",
  initialState: {
    reduxShowAllUsers_Data: "",
    reduxShowAllUsers_isError: "",
    reduxShowAllUsers_isLoading: false,

    AddPriceToNftByAdmin_Data: "",
    AddPriceToNftByAdmin_isError: "",
    AddPriceToNftByAdmin_isLoading: false,

    editPriceToNftByAdmin_Data: "",
    editPriceToNftByAdmin_isError: "",
    editPriceToNftByAdmin_isLoading: false,

    deleteNFTpriceAdmin_Data: "",
    deleteNFTpriceAdmin_isError: "",
    deleteNFTpriceAdmin_isLoading: false,

    dashboardCount_Data: "",
    dashboardCount_isError: "",
    dashboardCount_isLoading: false,

    showAllplan_Data: "",
    showAllplan_isError: "",
    showAllplan_isLoading: false,

    showUserById_Data: "",
    showUserById_isError: "",
    showUserById_isLoading: false,

    getUploadImagesAdmin_Data: "",
    getUploadImagesAdmin_isError: "",
    getUploadImagesAdmin_isLoading: false,

    showAllWithdraw_Data: "",
    showAllWithdraw_isError: "",
    showAllWithdraw_isLoading: false,

    changeWithdrawStatus_Data: "",
    changeWithdrawStatus_isError: "",
    changeWithdrawStatus_isLoading: false,

    showUserReferals_Data: "",
    showUserReferals_isError: "",
    showUserReferals_isLoading: false,

    showWalletHistory_Data: "",
    showWalletHistory_isError: "",
    showWalletHistory_isLoading: false,

    updateWallet_Data: "",
    updateWallet_isError: "",
    updateWallet_isLoading: false,

    changePlanStatus_Data: "",
    changePlanStatus_isError: "",
    changePlanStatus_isLoading: false,

    showWithdrawHistory_Data: "",
    showWithdrawHistory_isError: "",
    showWithdrawHistory_isLoading: false,

    AddDividentByAdmin_Data: "",
    AddDividentByAdmin_isError: "",
    AddDividentByAdmin_isLoading: false,
  },
  reducers: {
    clearChangeWithdrawStatus: (state) => {
      state.changeWithdrawStatus_Data = "";
      state.changeWithdrawStatus_isError = "";
      state.changeWithdrawStatus_isLoading = false;
    },
    clearChangePlanStatus: (state) => {
      state.changePlanStatus_Data = "";
      state.changePlanStatus_isError = "";
      state.changePlanStatus_isLoading = false;
    },
    clearUpdateWallet: (state) => {
      state.updateWallet_Data = "";
      state.updateWallet_isError = "";
      state.updateWallet_isLoading = false;
    },
    clearAddDividentByAdmin: (state) => {
      state.AddDividentByAdmin_Data = "";
      state.AddDividentByAdmin_isError = "";
      state.AddDividentByAdmin_isLoading = false;
    },
    clearAddPriceToNftByAdmin: (state) => {
      state.AddPriceToNftByAdmin_Data = "";
      state.AddPriceToNftByAdmin_isError = "";
      state.AddPriceToNftByAdmin_isLoading = false;
    },
    cleareditPriceToNftByAdmin: (state) => {
      state.editPriceToNftByAdmin_Data = "";
      state.editPriceToNftByAdmin_isError = "";
      state.editPriceToNftByAdmin_isLoading = false;
    },
    cleardeleteNFTpriceAdmin: (state) => {
      state.deleteNFTpriceAdmin_Data = "";
      state.deleteNFTpriceAdmin_isError = "";
      state.deleteNFTpriceAdmin_isLoading = false;
    },
  },
  extraReducers: {
    [AddPriceToNftByAdmin.pending]: (state) => {
      state.AddPriceToNftByAdmint_isLoading = true;
    },
    [AddPriceToNftByAdmin.fulfilled]: (state, actions) => {
      state.AddPriceToNftByAdmin_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.AddPriceToNftByAdmin_isError = data;
      } else {
        state.AddPriceToNftByAdmin_Data = data;
        state.AddPriceToNftByAdmin_isError = "";
      }
    },
    [AddPriceToNftByAdmin.rejected]: (state) => {
      state.AddPriceToNftByAdmin_isLoading = false;
    },

    [editPriceToNftByAdmin.pending]: (state) => {
      state.editPriceToNftByAdmin_isLoading = true;
    },
    [editPriceToNftByAdmin.fulfilled]: (state, actions) => {
      state.editPriceToNftByAdmin_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.editPriceToNftByAdmin_isError = data;
      } else {
        state.editPriceToNftByAdmin_Data = data;
        state.editPriceToNftByAdmin_isError = "";
      }
    },
    [editPriceToNftByAdmin.rejected]: (state) => {
      state.editPriceToNftByAdmin_isLoading = false;
    },

    [deleteNFTpriceAdmin.pending]: (state) => {
      state.deleteNFTpriceAdmin_isLoading = true;
    },
    [deleteNFTpriceAdmin.fulfilled]: (state, actions) => {
      state.deleteNFTpriceAdmin_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.deleteNFTpriceAdmin_isError = data;
      } else {
        state.deleteNFTpriceAdmin_Data = data;
        state.deleteNFTpriceAdmin_isError = "";
      }
    },
    [deleteNFTpriceAdmin.rejected]: (state) => {
      state.deleteNFTpriceAdmin_isLoading = false;
    },

    [dashboardCount.pending]: (state) => {
      state.dashboardCount_isLoading = true;
    },
    [dashboardCount.fulfilled]: (state, actions) => {
      state.dashboardCount_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.dashboardCount_isError = data;
      } else {
        state.dashboardCount_Data = data;
        state.dashboardCount_isError = "";
      }
    },
    [dashboardCount.rejected]: (state) => {
      state.dashboardCount_isLoading = false;
    },

    [AddDividentByAdmin.pending]: (state) => {
      state.AddDividentByAdmin_isLoading = true;
    },
    [AddDividentByAdmin.fulfilled]: (state, actions) => {
      state.AddDividentByAdmin_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.AddDividentByAdmin_isError = data;
      } else {
        state.AddDividentByAdmin_Data = data;
        state.AddDividentByAdmin_isError = "";
      }
    },
    [AddDividentByAdmin.rejected]: (state) => {
      state.AddDividentByAdmin_isLoading = false;
    },

    [showAllUsers.pending]: (state) => {
      state.reduxShowAllUsers_isLoading = true;
    },
    [showAllUsers.fulfilled]: (state, actions) => {
      state.reduxShowAllUsers_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.reduxShowAllUsers_isError = data;
      } else {
        state.reduxShowAllUsers_Data = data;
        state.reduxShowAllUsers_isError = "";
      }
    },
    [showAllUsers.rejected]: (state) => {
      state.reduxShowAllUsers_isLoading = false;
    },

    [getUploadImagesAdmin.pending]: (state) => {
      state.getUploadImagesAdmin_isLoading = true;
    },
    [getUploadImagesAdmin.fulfilled]: (state, actions) => {
      state.getUploadImagesAdmin_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.getUploadImagesAdmin_isError = data;
      } else {
        state.getUploadImagesAdmin_Data = data;
        state.getUploadImagesAdmin_isError = "";
      }
    },
    [getUploadImagesAdmin.rejected]: (state) => {
      state.getUploadImagesAdmin_isLoading = false;
    },

    [showAllplan.pending]: (state) => {
      state.showAllplan_isLoading = true;
    },

    [showAllplan.fulfilled]: (state, actions) => {
      state.showAllplan_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showAllplan_isError = data;
      } else {
        state.showAllplan_Data = data;
        state.showAllplan_isError = "";
      }
    },

    [showAllplan.rejected]: (state) => {
      state.showAllplan_isLoading = false;
    },

    [changePlanStatus.pending]: (state) => {
      state.changePlanStatus_isLoading = true;
    },
    [changePlanStatus.fulfilled]: (state, actions) => {
      state.changePlanStatus_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.changePlanStatus_isError = data;
      } else {
        state.changePlanStatus_Data = data;
        state.changePlanStatus_isError = "";
      }
    },
    [changePlanStatus.rejected]: (state) => {
      state.changePlanStatus_isLoading = false;
    },

    [showUserById.pending]: (state) => {
      state.showUserById_isLoading = true;
    },
    [showUserById.fulfilled]: (state, actions) => {
      state.showUserById_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showUserById_isError = data;
      } else {
        state.showUserById_Data = data;
        state.showUserById_isError = "";
      }
    },
    [showUserById.rejected]: (state) => {
      state.showUserById_isLoading = false;
    },

    [showAllWithdraw.pending]: (state) => {
      state.showAllWithdraw_isLoading = true;
    },
    [showAllWithdraw.fulfilled]: (state, actions) => {
      state.showAllWithdraw_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showAllWithdraw_isError = data;
      } else {
        state.showAllWithdraw_Data = data;
        state.showAllWithdraw_isError = "";
      }
    },
    [showAllWithdraw.rejected]: (state) => {
      state.showAllWithdraw_isLoading = false;
    },

    [changeWithdrawStatus.pending]: (state) => {
      state.changeWithdrawStatus_isLoading = true;
    },
    [changeWithdrawStatus.fulfilled]: (state, actions) => {
      state.changeWithdrawStatus_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.changeWithdrawStatus_isError = data;
      } else {
        state.changeWithdrawStatus_Data = data;
        state.changeWithdrawStatus_isError = "";
      }
    },
    [changeWithdrawStatus.rejected]: (state) => {
      state.changeWithdrawStatus_isLoading = false;
    },

    [showUserReferals.pending]: (state) => {
      state.showUserReferals_isLoading = true;
    },
    [showUserReferals.fulfilled]: (state, actions) => {
      state.showUserReferals_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showUserReferals_isError = data;
      } else {
        state.showUserReferals_Data = data;
        state.showUserReferals_isError = "";
      }
    },
    [showUserReferals.rejected]: (state) => {
      state.showUserReferals_isLoading = false;
    },

    [updateWallet.pending]: (state) => {
      state.updateWallet_isLoading = true;
    },
    [updateWallet.fulfilled]: (state, actions) => {
      state.updateWallet_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.updateWallet_isError = data;
      } else {
        state.updateWallet_Data = data;
        state.updateWallet_isError = "";
      }
    },
    [updateWallet.rejected]: (state) => {
      state.updateWallet_isLoading = false;
    },

    [showWithdrawHistory.pending]: (state) => {
      state.showWithdrawHistory_isLoading = true;
    },
    [showWithdrawHistory.fulfilled]: (state, actions) => {
      state.showWithdrawHistory_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showWithdrawHistory_isError = data;
      } else {
        state.showWithdrawHistory_Data = data;
        state.showWithdrawHistory_isError = "";
      }
    },
    [showWithdrawHistory.rejected]: (state) => {
      state.showWithdrawHistory_isLoading = false;
    },
    [showWalletHistory.pending]: (state) => {
      state.showWalletHistory_isLoading = true;
    },
    [showWalletHistory.fulfilled]: (state, actions) => {
      state.showWalletHistory_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.showWalletHistory_isError = data;
      } else {
        state.showWalletHistory_Data = data;
        state.showWalletHistory_isError = "";
      }
    },
    [showWalletHistory.rejected]: (state) => {
      state.showWalletHistory_isLoading = false;
    },
  },
});
export default UserSlice.reducer;
export const {
  clearChangeWithdrawStatus,
  clearUpdateWallet,
  clearChangePlanStatus,
  clearAddDividentByAdmin,
  clearAddPriceToNftByAdmin,
  cleareditPriceToNftByAdmin,
  cleardeleteNFTpriceAdmin,
} = UserSlice.actions;
