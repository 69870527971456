import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Radio,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePlanStatus,
  clearChangePlanStatus,
  showAllplan,
  showAllUsers,
} from "../../redux/UserReducer";
import {
  UserCardHeaderText,
  UserCardText,
} from "../../styles/styledComponents";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { green, red, yellow } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { USER_DETAIL } from "../../routes/pathConstants";
import { useNavigate } from "react-router-dom";
const Ico = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    mainData: [],
    data: [],
    status: 3,
    dialog: false,
    dialogStatus: "",
    userId: "",
    planId: "",
  });

  const {
    changePlanStatus_Data,
    changePlanStatus_isError,
    changePlanStatus_isLoading,

    showAllplan_Data,
    showAllplan_isError,
    showAllplan_isLoading,
  } = useSelector((store) => store.userStore);

  const handleChangePlan = () => {
    const formData = new FormData();
    formData.append("user_id", state.userId);
    formData.append("plan_status", state.dialogStatus);
    formData.append("plan_id", state.dialogStatus);
    dispatch(changePlanStatus(formData));
  };
  const handleCloseModal = () => {
    setState({
      ...state,
      dialog: false,
      dialogStatus: "",
      userId: "",
      planId: "",
    });
  };
  useEffect(() => {
    if (changePlanStatus_Data) {
      handleCloseModal();
      dispatch(showAllplan());
      dispatch(clearChangePlanStatus());
      toast.success("Status Changed Successfully");
    }
    if (changePlanStatus_isError) {
      dispatch(clearChangePlanStatus());
      toast.error("something went wrong");
    }
  }, [changePlanStatus_Data, changePlanStatus_isError]);

  useEffect(() => {
    if (showAllplan_Data) {
      const datas = showAllplan_Data?.data?.filter(
        (res) => res?.filter === "ico"
      );
      setState({ ...state, data: datas, mainData: datas });
    }
  }, [showAllplan_Data]);

  useEffect(() => {
    switch (state.status) {
      case 3:
        setState({ ...state, data: state.mainData });
        break;
      case 2:
        const filteredDataRejected = state.mainData?.filter(
          (item) => item?.status === 2
        );
        setState({ ...state, data: filteredDataRejected });
        break;
      case 1:
        const filteredDataApproved = state.mainData?.filter(
          (item) => item?.status === 1
        );
        setState({ ...state, data: filteredDataApproved });
        break;
      case 0:
        const filteredDataPending = state.mainData?.filter(
          (item) => item?.status === 0
        );
        setState({ ...state, data: filteredDataPending });
        break;

      default:
        break;
    }
  }, [state.status]);

  useEffect(() => {
    dispatch(showAllplan());
  }, []);

  const icoPlanName = ["", "1 Hub", "1/2 hub", "1/4 hub", "1/8 hub"];
  return (
    <>
      <Dialog
        open={state.dialog}
        onClose={handleCloseModal}
        PaperProps={{
          sx: { minWidth: "350px", py: 1.4 },
        }}
      >
        <Stack
          sx={{ px: 2 }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Edit</Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <Box sx={{ px: 2, pt: 1 }}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignitems="center"
          >
            <Typography>Pending</Typography>
            <Radio
              checked={state.dialogStatus === 0 ? true : false}
              onClick={() => setState({ ...state, dialogStatus: 0 })}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignitems="center"
            sx={{ mt: 1 }}
          >
            <Typography>Approve</Typography>
            <Radio
              checked={state.dialogStatus === 1 ? true : false}
              onClick={() => setState({ ...state, dialogStatus: 1 })}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignitems="center"
            sx={{ mt: 1 }}
          >
            <Typography>Reject</Typography>
            <Radio
              checked={state.dialogStatus === 2 ? true : false}
              onClick={() => setState({ ...state, dialogStatus: 2 })}
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
            {changePlanStatus_isLoading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={handleChangePlan}>
                Submit
              </Button>
            )}
          </Stack>
        </Box>
      </Dialog>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="center"
        sx={{ mb: 2 }}
      >
        <Button
          variant={state.status === 3 ? "contained" : "outlined"}
          onClick={() => setState({ ...state, status: 3 })}
        >
          All
        </Button>
        <Button
          onClick={() => setState({ ...state, status: 1 })}
          variant={state.status === 1 ? "contained" : "outlined"}
        >
          Approved
        </Button>
        <Button
          onClick={() => setState({ ...state, status: 0 })}
          variant={state.status === 0 ? "contained" : "outlined"}
        >
          Pending
        </Button>
        <Button
          onClick={() => setState({ ...state, status: 2 })}
          variant={state.status === 2 ? "contained" : "outlined"}
        >
          Rejected
        </Button>
      </Stack>
      <Stack direction="row">
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Txn Id</UserCardHeaderText>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Amount</UserCardHeaderText>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Plan Type</UserCardHeaderText>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Status</UserCardHeaderText>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Actions</UserCardHeaderText>
        </Stack>
      </Stack>
      {showAllplan_isLoading ? (
        <CircularProgress />
      ) : (
        state.data?.length > 0 &&
        state.data?.map((item, ItemIndex) => (
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: 5,
              p: 1,
              borderRadius: 1,
              my: 1.2,
            }}
            key={ItemIndex}
          >
            <Stack direction="row" sx={{ mt: 1 }}>
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardText>{item?.txn_id}</UserCardText>
              </Stack>
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardText>₹{item?.amount}</UserCardText>
              </Stack>
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardText>
                  {icoPlanName[Number(item?.plan_type)]}
                </UserCardText>
              </Stack>
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <Box>
                  {item?.status === 0 && (
                    <Box
                      sx={{
                        backgroundColor: yellow[700],
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 4,
                      }}
                    >
                      <p style={{ color: "#fff" }}>Pending</p>
                    </Box>
                  )}
                  {item?.status === 1 && (
                    <Box
                      sx={{
                        backgroundColor: green[700],
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 4,
                      }}
                    >
                      <p style={{ color: "#fff" }}>Approved</p>
                    </Box>
                  )}
                  {item?.status === 2 && (
                    <Box
                      sx={{
                        backgroundColor: red[500],

                        px: 1.5,
                        py: 0.5,
                        borderRadius: 4,
                      }}
                    >
                      <p style={{ color: "#fff" }}>Rejected</p>
                    </Box>
                  )}
                </Box>
              </Stack>

              <Stack
                sx={{ flex: 1, mt: "-10px" }}
                direction="row"
                justifyContent={"center"}
                spacing={1}
              >
                <IconButton
                  onClick={() =>
                    setState({
                      ...state,
                      dialog: true,
                      dialogStatus: Number(item?.status),
                      userId: item?.user_id,
                      planId: item?.id,
                    })
                  }
                >
                  <EditIcon
                    sx={{ color: "#000" }}
                    onClick={() => setState({ ...state })}
                  />
                </IconButton>
                <IconButton>
                  <VisibilityIcon
                    color="success"
                    onClick={() => navigate(`${USER_DETAIL}/${item?.id}`)}
                  />
                </IconButton>
                <IconButton>
                  <DeleteIcon color="error" />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        ))
      )}
    </>
  );
};

export default Ico;
