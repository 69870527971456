import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../api/axiosIntance";

export const loginReducer = createAsyncThunk("login", async (body) => {
  try {
    const { data } = await axiosInstance.post("/loginAdmin", body);
    return { data };
  } catch (error) {
    const { data, status } = error.response;
    return { data, status };
  }
});
const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    reduxLogin_Data: "",
    reduxLogin_isError: "",
    reduxLogin_isLoading: false,
  },
  reducers: {
    logOut: (state) => {
      state.reduxLogin_Data = "";
      state.reduxLogin_isError = "";
      state.reduxLogin_isLoading = false;
      localStorage.removeItem("authTokenArtAuraAdmin");
    },
  },
  extraReducers: {
    [loginReducer.pending]: (state) => {
      state.reduxLogin_isLoading = true;
    },
    [loginReducer.fulfilled]: (state, actions) => {
      state.reduxLogin_isLoading = false;
      const { data, status } = actions.payload;
      if (status >= 400) {
        state.reduxLogin_isError = data;
      } else {
        state.reduxLogin_Data = data;
        localStorage.setItem("authTokenArtAuraAdmin", JSON.stringify(data));
        state.reduxLogin_isError = "";
      }
    },
    [loginReducer.rejected]: (state) => {
      state.reduxLogin_isLoading = false;
    },
  },
});
export default AuthSlice.reducer;
export const { logOut } = AuthSlice.actions;
