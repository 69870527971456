import { Collapse, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { sidebarData } from "../../utils/sidebarData";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./sidebar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { DASHBOARD, MEMBERSHIP, USERS } from "../../routes/pathConstants";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/AuthReducer";
const Sidebar = () => {
  const [state, setState] = useState("");
  const [sidebartext, setSidebartext] = useState([]);
  const [filterData, setFitlerData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const onClickCollapse = (item) => {
    if (item.path === DASHBOARD || item.path === USERS) {
      navigate(item.path);
    }
    if (item.name === state) {
      setState("");
    } else {
      setState(item.name);
    }
  };
  const filteringPathName = () => {
    let filteredData = [];
    sidebarData.forEach((item) => {
      if (item.collapse) {
        item.collapse.forEach((res) => {
          filteredData.push({
            name: res.name,
            path: res.path,
          });
        });
      }
    });
    setFitlerData(filteredData);
  };

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/login");
  };
  const onClickPath = (path) => {
    navigate(path);
  };

  useEffect(() => {
    filteringPathName();
  }, []);
  return (
    <div className="sidebar_container">
      {sidebarData.map((item, index) => (
        <div key={index}>
          <div
            onClick={() => onClickCollapse(item)}
            className={`sidebar_buttonWrap ${
              state === item.name ||
              location?.pathname?.includes(item.name?.toLocaleLowerCase())
                ? "active"
                : null
            }`}
          >
            <div className="icon_container">
              <div className="sidebar_iconFirstWrap">
                {item.icon}
                <p
                  style={
                    state === item.name ||
                    location?.pathname?.includes(item.name?.toLocaleLowerCase())
                      ? { color: "#fff" }
                      : null
                  }
                >
                  {item.name}
                </p>
              </div>
              <div>
                {item.collapse ? (
                  <ArrowForwardIosIcon
                    className="sidebar_icon"
                    fontSize="1rem"
                    key={item.name}
                    sx={
                      state === item.name ||
                      location?.pathname?.includes(
                        item.name?.toLocaleLowerCase()
                      )
                        ? {
                            transform: "rotate(90deg)",
                            transition: "all .5s",
                          }
                        : { transform: "rotate(0deg)", transition: "all .5s" }
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
          {item.collapse ? (
            <Collapse
              in={
                state === item.name ||
                location?.pathname?.includes(item.name?.toLocaleLowerCase())
                  ? true
                  : false
              }
            >
              {item.collapse.map((res, i) => (
                <div
                  className="sidebar_collapseItem"
                  // onClick={() => onClickPath(res.path)}
                  onClick={() => onClickPath(res.path)}
                  key={i}
                >
                  {res.icon}
                  <p
                    style={{ color: res.path === location.pathname && "#fff" }}
                  >
                    {res.name}
                  </p>
                </div>
              ))}
            </Collapse>
          ) : null}
        </div>
      ))}
      <div onClick={handleLogout} className={`sidebar_buttonWrap`}>
        <div className="icon_container">
          <div className="sidebar_iconFirstWrap">
            <LogoutIcon className="sidebar_icon" />
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
