import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export const UserCardHeaderText = styled(Typography)(({ theme }) => ({
  color: grey[700],
  fontSize: "20px",
  fontWeight: 550,
}));
export const UserCardText = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: "16px",
  fontWeight: 400,
}));
