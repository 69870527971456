import axios from "axios";
const baseURL = "https://apis.artauraa.com/laravelapi/public/api/auth";
const axiosInstance = axios.create({
  baseURL,
});
axiosInstance.interceptors.request.use(async (req) => {
  try {
    let authTokenArtAuraAdmin = false;
    if (localStorage.getItem("authTokenArtAuraAdmin")) {
      authTokenArtAuraAdmin = JSON.parse(
        localStorage.getItem("authTokenArtAuraAdmin")
      );
    }
    if (authTokenArtAuraAdmin) {
      req.headers[
        "Authorization"
      ] = `Bearer ${authTokenArtAuraAdmin?.access_token}`;
      req.headers["Content-Type"] = "application/json";
    }
  } catch (error) {
    console.log(error, "in instance error");
  }
  return req;
});

export default axiosInstance;
