import React from "react";
import "./UpperCard.scss";
import CountUp from "react-countup";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Stack } from "@mui/material";
const UpperCard = ({ backgroudnColor, name, icon, text, number }) => {
  return (
    <div
      className="dashboard_card__container"
      style={{ backgroundColor: backgroudnColor }}
    >
      <div className="dashboard_card_top">
        <Stack direction="column">
          <h3>
            <CountUp startVal={10} duration={2} end={number} />
          </h3>
          <h4>{text}</h4>
        </Stack>
        {icon}
      </div>
      <div className="dashboard_card_bottom">
        <Stack direction="row" alignItems="center">
          <h5>More Info</h5>
          <ArrowCircleRightIcon
            sx={{ color: "#fff", fontSize: "1rem", marginLeft: ".3rem" }}
          />
        </Stack>
      </div>
    </div>
  );
};

export default UpperCard;
