import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Radio,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPlan, showAllUsers } from "../../redux/UserReducer";
import {
  UserCardHeaderText,
  UserCardText,
} from "../../styles/styledComponents";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { green, red, yellow } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { USER_DETAIL } from "../../routes/pathConstants";
const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    reduxShowAllUsers_Data,
    reduxShowAllUsers_isError,
    reduxShowAllUsers_isLoading,
  } = useSelector((store) => store.userStore);
  useEffect(() => {
    dispatch(showAllUsers());
  }, []);
  return (
    <Box sx={{ width: "90%", m: "auto" }}>
      <Stack direction="row">
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Name</UserCardHeaderText>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Mobile</UserCardHeaderText>
        </Stack>
        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Email</UserCardHeaderText>
        </Stack>

        <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
          <UserCardHeaderText>Actions</UserCardHeaderText>
        </Stack>
      </Stack>
      {reduxShowAllUsers_isLoading ? (
        <CircularProgress />
      ) : (
        reduxShowAllUsers_Data?.users?.map((item, ItemIndex) => (
          <>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: 3,
                p: 1,
                borderRadius: 1,
                my: 1.2,
              }}
              key={ItemIndex}
            >
              <Stack direction="row" sx={{ mt: 1 }}>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <UserCardText sx={{ textTransform: "capitalize" }}>
                    {item?.name}
                  </UserCardText>
                </Stack>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <UserCardText>{item?.mobile}</UserCardText>
                </Stack>

                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <UserCardText>{item?.email}</UserCardText>
                </Stack>
                <Stack
                  sx={{ flex: 1, mt: "-10px" }}
                  direction="row"
                  justifyContent={"center"}
                  spacing={1}
                >
                  <IconButton
                    onClick={() => navigate(`${USER_DETAIL}/${item?.id}`)}
                  >
                    <VisibilityIcon color="success" />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          </>
        ))
      )}
    </Box>
  );
};

export default Users;
