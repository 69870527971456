import {
  DASHBOARD,
  ICO,
  MEMBERSHIP,
  USERS,
  WITHDRAW,
} from "../routes/pathConstants";
import AppsIcon from "@mui/icons-material/Apps";
import { Dashboard, Person, SettingsSuggest } from "@mui/icons-material";

export const sidebarData = [
  {
    name: "Dashboard",
    icon: <Dashboard className="sidebar_icon" />,
    collapse: false,
    path: DASHBOARD,
  },

  {
    name: "Account",
    icon: <SettingsSuggest className="sidebar_icon" />,
    collapse: [
      {
        name: "Withdraw",
        icon: "",
        path: WITHDRAW,
      },
    ],
  },

  {
    name: "Plans",
    icon: <AppsIcon className="sidebar_icon" />,
    collapse: [
      {
        name: "Membership",
        icon: "",
        path: MEMBERSHIP,
      },
      {
        name: "ICO",
        icon: "",
        path: ICO,
      },
    ],
  },
  {
    name: "Users",
    icon: <Person className="sidebar_icon" />,
    collapse: false,
    path: USERS,
  },
];
