import React from "react";
import "./navbar.scss";
import { IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const Navbar = ({ onClickMenu }) => {
  return (
    <Box className="navbar_container" sx={{ backgroundColor: "primary.main" }}>
      <img src="https://pcghomes.co.nz/dashboard/images/logo.png" alt="logo" />
      <h3>ArtAura</h3>
      <IconButton className="menu_icon_container" onClick={onClickMenu}>
        <MenuIcon className="menu_icon" />
      </IconButton>
    </Box>
  );
};

export default Navbar;
