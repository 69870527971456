import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Radio,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPlan,
  changeWithdrawStatus,
  clearChangeWithdrawStatus,
  showAllUsers,
  showAllWithdraw,
} from "../../redux/UserReducer";
import {
  UserCardHeaderText,
  UserCardText,
} from "../../styles/styledComponents";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { blue, green, red, yellow } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
const ShowModelText = ({ firstText, secondText }) => {
  return (
    <Grid container sx={{ py: 0.5 }}>
      <Grid item xs={6}>
        <Typography sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}>
          {firstText}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{
            color: "rgba(0,0,0,1)",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
        >
          {secondText}
        </Typography>
      </Grid>
    </Grid>
  );
};
const ShowAllWithraw = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    dialog: false,
    name: "",
    amount: "",
    ifsc: "",
    bankName: "",
    acc: "",
    status: "",
    userId: "",
    withdrawId: "",
    wallet_type: "",
  });
  const handleCloseDialog = () => {
    setState({
      ...state,
      dialog: false,
      name: "",
      amount: "",
      ifsc: "",
      bankName: "",
      acc: "",
      status: "",
      userId: "",
      withdrawId: "",
      wallet_type: "",
    });
  };
  const handleChangeWithdrawStatus = (status) => {
    const formData = new FormData();
    formData.append("user_id", state.userId);
    formData.append("withdraw_status", status);
    formData.append("withdraw_id", state.withdrawId);
    formData.append("wallet_type", state.wallet_type);
    dispatch(changeWithdrawStatus(formData));
  };
  const handleOnClickView = (item) => {
    setState({
      ...state,
      dialog: true,
      name: item?.account_holder_name,
      amount: item?.amount,
      ifsc: item?.ifsc_code,
      acc: item?.account_no,
      bankName: item?.bank_name,
      status: item?.status,
      userId: item?.user_id,
      withdrawId: item?.id,
      wallet_type: item?.wallet_type,
    });
  };
  const {
    showAllWithdraw_Data,
    showAllWithdraw_isError,
    showAllWithdraw_isLoading,

    changeWithdrawStatus_Data,
    changeWithdrawStatus_isError,
    changeWithdrawStatus_isLoading,
  } = useSelector((store) => store.userStore);

  useEffect(() => {
    if (changeWithdrawStatus_Data) {
      dispatch(clearChangeWithdrawStatus());
      dispatch(showAllWithdraw());
      handleCloseDialog();
      toast.success("withdraw status updated");
    }

    if (changeWithdrawStatus_isError) {
      toast.error("something went wrong");
      dispatch(clearChangeWithdrawStatus());
    }
  }, [changeWithdrawStatus_Data, changeWithdrawStatus_isError]);

  useEffect(() => {
    dispatch(showAllWithdraw());
  }, []);

  return (
    <>
      <Dialog
        open={state.dialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "400px" }, p: 2 },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h5">Payment Detail</Typography>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 1 }} />

        <ShowModelText
          firstText={"Account Holder Name"}
          secondText={state.name}
        />
        <ShowModelText firstText={"Account Number"} secondText={state.acc} />
        <ShowModelText firstText={"Bank Name"} secondText={state.bankName} />
        <ShowModelText firstText={"IFSC Code"} secondText={state.ifsc} />
        <ShowModelText
          firstText={"Request Amount"}
          secondText={`₹${state.amount}`}
        />
        <Grid container sx={{ py: 0.5 }}>
          <Grid item xs={6}>
            <Typography sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box>
              {state?.status === 0 && (
                <Box
                  sx={{
                    display: "inline-block",
                    backgroundColor: yellow[700],
                    px: 1,
                    py: 0.3,
                    borderRadius: 4,
                  }}
                >
                  <p style={{ color: "#fff" }}>Pending</p>
                </Box>
              )}
              {state?.status === 1 && (
                <Box
                  sx={{
                    backgroundColor: green[700],
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 4,
                    display: "inline-block",
                  }}
                >
                  <p style={{ color: "#fff" }}>Approved</p>
                </Box>
              )}
              {state?.status === 2 && (
                <Box
                  sx={{
                    backgroundColor: red[500],

                    px: 1.5,
                    py: 0.5,
                    borderRadius: 4,
                    display: "inline-block",
                  }}
                >
                  <p style={{ color: "#fff" }}>Rejected</p>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ mt: 2 }}
          justifyContent="center"
        >
          {/* {state.status !== "0" && (
            <Button
              size="small"
              color="warning"
              variant="contained"
              onClick={() => handleChangeWithdrawStatus(0)}
            >
              Pending
            </Button>
          )} */}
          {state.status !== 1 && (
            <Button
              size="small"
              color="success"
              variant="contained"
              onClick={() => handleChangeWithdrawStatus(1)}
            >
              Approve
            </Button>
          )}
          {state.status !== 2 && (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={() => handleChangeWithdrawStatus(2)}
            >
              Reject
            </Button>
          )}
        </Stack>
      </Dialog>
      <Box sx={{ width: "80%", m: "auto" }}>
        <Stack direction="row">
          <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
            <UserCardHeaderText>Name</UserCardHeaderText>
          </Stack>
          <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
            <UserCardHeaderText>Amount</UserCardHeaderText>
          </Stack>

          <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
            <UserCardHeaderText>Wallet Type</UserCardHeaderText>
          </Stack>
          <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
            <UserCardHeaderText>Status</UserCardHeaderText>
          </Stack>
          <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
            <UserCardHeaderText>Action</UserCardHeaderText>
          </Stack>
        </Stack>
        {showAllWithdraw_isLoading ? (
          <CircularProgress />
        ) : (
          showAllWithdraw_Data?.data?.map((item, ItemIndex) => (
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: 3,
                p: 1,
                borderRadius: 1,
                my: 1.2,
              }}
              key={ItemIndex}
            >
              <Stack direction="row" sx={{ mt: 1 }}>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <UserCardText sx={{ textTransform: "capitalize" }}>
                    {item?.account_holder_name}
                  </UserCardText>
                </Stack>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <UserCardText>&#8377; {item?.amount}</UserCardText>
                </Stack>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <UserCardText>{item?.wallet_type}</UserCardText>
                </Stack>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <Box>
                    {item?.status === 0 && (
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Box
                          sx={{
                            backgroundColor: yellow[700],
                            px: 1,
                            py: 0.3,
                            borderRadius: 4,
                          }}
                        >
                          <p style={{ color: "#fff" }}>Pending</p>
                        </Box>
                        {/* <MoreVertIcon sx={{ color: "#000" }} /> */}
                      </Stack>
                    )}
                    {item?.status === 1 && (
                      <Box
                        sx={{
                          backgroundColor: green[700],
                          px: 1.5,
                          py: 0.5,
                          borderRadius: 4,
                        }}
                      >
                        <p style={{ color: "#fff" }}>Approved</p>
                      </Box>
                    )}
                    {item?.status === 2 && (
                      <Box
                        sx={{
                          backgroundColor: red[500],

                          px: 1.5,
                          py: 0.5,
                          borderRadius: 4,
                        }}
                      >
                        <p style={{ color: "#fff" }}>Rejected</p>
                      </Box>
                    )}
                  </Box>
                </Stack>
                <Stack
                  sx={{ flex: 1 }}
                  direction="row"
                  justifyContent={"center"}
                >
                  <Typography
                    onClick={() => handleOnClickView(item)}
                    sx={{
                      color: blue[700],
                      cursor: "pointer",
                      textDecoration: "underline",
                      "&:hover": {
                        color: blue[900],
                      },
                    }}
                  >
                    view
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ))
        )}
      </Box>
    </>
  );
};

export default ShowAllWithraw;
