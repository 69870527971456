import React from "react";
import jwtDecode from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const ProtectedRoute = () => {
  const { reduxLogin_Data } = useSelector((store) => store.authStore);
  const dt = new Date();
  const token = localStorage.getItem("authTokenArtAuraAdmin");
  let isValid = false;
  if (token) {
    try {
      console.log(token, "in tokent");
      const parseToken = JSON.parse(token);
      const { exp } = jwtDecode(parseToken?.access_token);
      console.log(parseToken, "in parse");
      if (dt.getTime() < exp * 1000) {
        console.log("in yes");
        isValid = true;
      }
    } catch (error) {}
  }

  if (reduxLogin_Data) {
    const decode = jwtDecode(reduxLogin_Data?.access_token);
    if (dt.getTime() < decode.exp * 1000) {
      isValid = true;
    }
  }

  return isValid ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
