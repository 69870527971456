import React, { useState, useEffect } from "react";
import { Navbar, Sidebar } from "../../components";
import "./home.scss";
import { ThemeColors } from "../../utils/theme";
import "./home.scss";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { DASHBOARD } from "../../routes/pathConstants";
import Dashboard from "../dashboard/Dashboard";
const Home = () => {
  const [state, setstate] = useState({
    nav: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const onClickMenu = () => {
    setstate({ ...state, nav: !state.nav });
  };
  return (
    <div>
      <Navbar onClickMenu={onClickMenu} />
      <div className="root_container">
        <div
          className={
            state.nav ? "sidebar_mainContainer" : "sidebar_mainContainer_active"
          }
          style={{ backgroundColor: ThemeColors.sidebarBackground }}
        >
          {!state.nav ? <Sidebar /> : null}
        </div>
        <div className="right_content">
          <div className="home_routesNmae">
            <h3>Dashboard</h3>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
