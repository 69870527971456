import {
  Paper,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { loginReducer } from "../../../redux/AuthReducer";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [state, setState] = useState({
    username: "",
    password: "",
    showPassword: false,
    err: {},
  });
  const navigate = useNavigate();
  const { reduxLogin_Data, reduxLogin_isError, reduxLogin_isLoading } =
    useSelector((store) => store.authStore);
  const dispatch = useDispatch();
  const handleLogin = () => {
    let err = {};
    if (!state.username.trim()) {
      err.username = "Username required";
    }
    if (!state.password.trim()) {
      err.password = "Password required";
    }
    if (Object.keys(err).length === 0) {
      const formData = new FormData();
      formData.append("email", state.username);
      formData.append("password", state.password);
      dispatch(loginReducer(formData));
      setState({ ...state, err: {} });
    } else {
      setState({ ...state, err: err });
    }
  };
  useEffect(() => {
    if (reduxLogin_Data) {
      toast.success("Login successfully");
      navigate("/");
    }
    if (reduxLogin_isError) {
      toast.error("Crenditial wrong");
    }
  }, [reduxLogin_Data, reduxLogin_isError]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper sx={{ p: 2, minWidth: "350px" }}>
        <Typography variant="h4" textAlign="center">
          Login
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Username
        </Typography>
        <TextField
          placeholder="example john@gmail.com"
          fullWidth
          size="small"
          value={state.username}
          onChange={(e) => setState({ ...state, username: e.target.value })}
        />
        {state.err.username && (
          <Typography color="error">{state.err.username}</Typography>
        )}
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Password
        </Typography>
        <TextField
          placeholder="*******"
          fullWidth
          type={state.showPassword ? "text" : "password"}
          size="small"
          value={state.password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {state.showPassword ? (
                  <VisibilityIcon
                    onClick={() => setState({ ...state, showPassword: false })}
                    sx={{ color: "#000", cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setState({ ...state, showPassword: true })}
                    sx={{ color: "#000", cursor: "pointer" }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        {state.err.password && (
          <Typography color="error">{state.err.password}</Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {reduxLogin_isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" onClick={handleLogin}>
              Login
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
