import { Provider, useDispatch } from "react-redux";
import "./App.css";
import store from "./redux/store";
import { RootRoutes } from "./routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import React from "react";
const theme = createTheme({});

function App() {
  return (
    <div className="body">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ToastContainer theme="colored" autoClose={3000} />
          <RootRoutes />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
