import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer";
import UserReducer from "./UserReducer";

const store = configureStore({
  reducer: {
    authStore: AuthReducer,
    userStore: UserReducer,
  },
});
export default store;
