import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import RouteNotFound from "../components/RouteNotFound";
import { Dashboard, Home, Login } from "../screens";
import { ICO, MEMBERSHIP, USERS, USER_DETAIL, WITHDRAW } from "./pathConstants";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logOut } from "../redux/AuthReducer";
import MembershipComponent from "../screens/membership/Membership";
import Users from "../screens/users/Users";
import UserDetail from "../screens/users/UserDetail";
import Ico from "../screens/membership/Ico";
import ShowAllWithraw from "../screens/account/ShoAllWithdraw";
import ProtectedRoute from "./ProtectedRoute";
const RootRoutes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const localToken = localStorage.getItem("authTokenArtAuraAdmin");
    const dt = new Date();
    if (localToken) {
      const { exp } = jwt_decode(
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd2ViaW5mb2xvZ2ljcy5jb21cL2xhcmF2ZWxhcGlcL3B1YmxpY1wvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY2Mjk2NzcwMSwiZXhwIjoxNjcxNjA3NzAxLCJuYmYiOjE2NjI5Njc3MDEsImp0aSI6IkxVdkxIUDRNaHoxOGVpbmMiLCJzdWIiOjIzLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.ldAHIGVg9VfEyyEOGqC_1sqDIv_DWhahkRwBnwx5vTY"
      );
      if (dt.getTime() > exp * 1000) {
        dispatch(logOut());
      }
    }
  }, []);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Home />}>
          <Route path="*" element={<RouteNotFound />} />
          <Route path="/" element={<Dashboard />} />
          <Route path={MEMBERSHIP} element={<MembershipComponent />} />
          <Route path={WITHDRAW} element={<ShowAllWithraw />} />
          <Route path={USERS} element={<Users />} />
          <Route path={`${USER_DETAIL}/:id`} element={<UserDetail />} />
          <Route path={ICO} element={<Ico />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default RootRoutes;
