import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  Collapse,
  Dialog,
  IconButton,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Paper,
} from "@mui/material";
import { blue, green, red, yellow } from "@mui/material/colors";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AddDividentByAdmin,
  AddPriceToNftByAdmin,
  clearAddDividentByAdmin,
  clearAddPriceToNftByAdmin,
  clearUpdateWallet,
  cleardeleteNFTpriceAdmin,
  cleareditPriceToNftByAdmin,
  deleteNFTpriceAdmin,
  editPriceToNftByAdmin,
  getUploadImagesAdmin,
  showUserById,
  showUserReferals,
  showWalletHistory,
  showWithdrawHistory,
  updateWallet,
} from "../../redux/UserReducer";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { Add, SportsRugbySharp } from "@mui/icons-material";
import {
  UserCardHeaderText,
  UserCardText,
} from "../../styles/styledComponents";
import { LoadingButton } from "@mui/lab";

const ShowModelText = ({ firstText, secondText }) => {
  return (
    <Grid container sx={{ py: 0.5 }}>
      <Grid item xs={6}>
        <Typography sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}>
          {firstText}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{
            color: "rgba(0,0,0,1)",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
        >
          {secondText}
        </Typography>
      </Grid>
    </Grid>
  );
};
const UserDetail = () => {
  const membershipPlanName = [
    "",
    "BASIC",
    "PREMIUM",
    "SUPER PREMIUM",
    "PARTNER",
  ];
  const icoPlanName = ["", "1 Hub", "1/2 hub", "1/4 hub", "1/8 hub"];
  const dividentPlan = [
    {
      id: 1,
      name: "1 Hub - ₹82,500 - $1000",
      amount: 82500,
    },
    {
      id: 2,
      name: "1/2 Hub - ₹41,500 - $500",
      amount: 41500,
    },
    {
      id: 3,
      name: "1/4 Hub - ₹22,000 - $250",
      amount: 22000,
    },
    {
      id: 4,
      name: "1/8 Hub - ₹12,000 - $125",
      amount: 12000,
    },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    showUserById_Data,
    showUserById_isError,
    showUserById_isLoading,

    showUserReferals_Data,
    showUserReferals_isError,
    showUserReferals_isLoading,

    showWalletHistory_Data,
    showWalletHistory_isError,
    showWalletHistory_isLoading,

    updateWallet_Data,
    updateWallet_isError,
    updateWallet_isLoading,

    AddDividentByAdmin_Data,
    AddDividentByAdmin_isError,
    AddDividentByAdmin_isLoading,

    AddPriceToNftByAdmin_Data,
    AddPriceToNftByAdmin_isError,
    AddPriceToNftByAdmin_isLoading,

    editPriceToNftByAdmin_Data,
    editPriceToNftByAdmin_isError,
    editPriceToNftByAdmin_isLoading,

    deleteNFTpriceAdmin_Data,
    deleteNFTpriceAdmin_isError,
    deleteNFTpriceAdmin_isLoading,

    getUploadImagesAdmin_Data,
    getUploadImagesAdmin_isError,
    getUploadImagesAdmin_isLoading,

    showWithdrawHistory_Data,
    showWithdrawHistory_isError,
    showWithdrawHistory_isLoading,
  } = useSelector((store) => store.userStore);

  const [nftPrice, setNftPrice] = useState({
    priceInr: 0,
    priceDollar: 0,
    nftAddress: "",
    dialog: false,
    edit: false,
    imgPath: "",
    editId: "",
    nftLink: "",
  });
  const [state, setState] = useState({
    tabStatus: 0,
    icoData: [],
    membershipData: [],
    collapseIndex: "",
    dialog: false,
    dividentDialog: false,
    divident: "",
    amnt: "",
    userId: "",
    action: "",
    remark: "",
    walletType: "",
  });
  const handleDelete = (guid) => {
    const formData = new FormData();
    formData.append("guid", guid);
    dispatch(deleteNFTpriceAdmin(formData));
  };
  const handleAddUpdateNftPrice = (e) => {
    e.preventDefault();
    if (nftPrice.edit) {
      const formData = new FormData();
      formData.append("guid", nftPrice.editId);
      formData.append("amount_inr", nftPrice.priceInr);
      formData.append("nft_address", nftPrice.nftAddress);
      formData.append("amount_usd", nftPrice.priceDollar);
      formData.append("img_path", nftPrice.imgPath);
      formData.append("nft_link", nftPrice.nftLink);
      dispatch(editPriceToNftByAdmin(formData));
    } else {
      const formData = new FormData();
      formData.append("user_id", id);
      formData.append("amount_inr", nftPrice.priceInr);
      formData.append("nft_address", nftPrice.nftAddress);
      formData.append("amount_usd", nftPrice.priceDollar);
      formData.append("img_path", nftPrice.imgPath);
      formData.append("nft_link", nftPrice.nftLink);
      dispatch(AddPriceToNftByAdmin(formData));
    }
  };
  const handleUpdateWallet = () => {
    const formData = new FormData();
    formData.append("amount", state.amnt);
    formData.append("user_id", state.userId);
    formData.append("action", state.action);
    formData.append("remark", state.remark);
    switch (state.walletType) {
      case "wallet":
        dispatch(updateWallet({ data: formData, url: "/updatewalletAdmin" }));
        break;
      case "reward":
        dispatch(updateWallet({ data: formData, url: "/updateRewardAdmin" }));
        break;
      case "awareness":
        dispatch(
          updateWallet({ data: formData, url: "/updateAwarenessBonusAdmin" })
        );
        break;
      case "field":
        dispatch(
          updateWallet({ data: formData, url: "/updateFieldBonusAdmin" })
        );
        break;

      default:
        break;
    }
  };
  const handleView = (index) => {
    if (index === state.collapseIndex) {
      setState({ ...state, collapseIndex: "" });
    } else {
      setState({ ...state, collapseIndex: index });
    }
  };
  const handleCloseModal = () => {
    setState({
      ...state,
      dialog: false,
      amnt: "",
      userId: "",
      action: "",
      remark: "",
    });
  };
  const handleCloseNftPriceModal = () => {
    setNftPrice((prev) => ({
      ...prev,
      priceInr: 0,
      priceDollar: 0,
      nftAddress: "",
      dialog: false,
      edit: false,
      imgPath: "",
      editId: "",
      nftLink: "",
    }));
  };
  const handleUpdateDivident = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = dividentPlan.find((item) => item.id === state.divident);
    formData.append("amount", data.amount);
    formData.append("plan_type", data.id);
    formData.append("user_id", id);
    dispatch(AddDividentByAdmin(formData));
  };
  const handleCloseDividentDialog = () => {
    setState({
      ...state,
      dividentDialog: false,
      divident: "",
    });
  };
  useEffect(() => {
    if (showUserById_Data) {
      const membership = showUserById_Data?.users?.userplans?.filter(
        (item) => item.filter === "membership"
      );
      const ico = showUserById_Data?.users?.userplans?.filter(
        (item) => item.filter === "ico"
      );
      setState({ ...state, icoData: ico, membershipData: membership });
    }
  }, [showUserById_Data]);
  useEffect(() => {
    if (updateWallet_Data) {
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(showUserById(formData));
      toast.success("Wallet Updated");
      handleCloseModal();
      dispatch(clearUpdateWallet());
    }
    if (updateWallet_isError) {
      toast.error("something went wrong");
      dispatch(clearUpdateWallet());
    }
    if (AddDividentByAdmin_Data) {
      handleCloseDividentDialog();
      setState((prev) => ({ ...prev, tabStatus: 4 }));
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(showUserById(formData));
      toast.success("Plan Added");
      dispatch(clearAddDividentByAdmin());
    }
    if (AddDividentByAdmin_isError) {
      toast.error("something went wrong");
      dispatch(clearAddDividentByAdmin());
    }
    if (AddPriceToNftByAdmin_Data) {
      handleCloseNftPriceModal();
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(getUploadImagesAdmin(formData));
      toast.success("Price Added");
      dispatch(clearAddPriceToNftByAdmin());
    }
    if (AddPriceToNftByAdmin_isError) {
      toast.error("something went wrong");
      dispatch(clearAddPriceToNftByAdmin());
    }
    if (editPriceToNftByAdmin_Data) {
      handleCloseNftPriceModal();
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(getUploadImagesAdmin(formData));
      toast.success("Price Added");
      dispatch(cleareditPriceToNftByAdmin());
    }
    if (editPriceToNftByAdmin_isError) {
      toast.error("something went wrong");
      dispatch(cleareditPriceToNftByAdmin());
    }
    if (deleteNFTpriceAdmin_Data) {
      handleCloseNftPriceModal();
      const formData = new FormData();
      formData.append("user_id", id);
      dispatch(getUploadImagesAdmin(formData));
      toast.success("Price Added");
      dispatch(cleardeleteNFTpriceAdmin());
    }
    if (deleteNFTpriceAdmin_isError) {
      toast.error("something went wrong");
      dispatch(cleardeleteNFTpriceAdmin());
    }
  }, [
    updateWallet_Data,
    updateWallet_isError,
    AddDividentByAdmin_Data,
    AddDividentByAdmin_isError,
    AddPriceToNftByAdmin_Data,
    AddPriceToNftByAdmin_isError,
    editPriceToNftByAdmin_Data,
    editPriceToNftByAdmin_isError,
    deleteNFTpriceAdmin_Data,
    deleteNFTpriceAdmin_isError,
  ]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("user_id", id);
    dispatch(showUserById(formData));
    dispatch(showUserReferals(formData));
    dispatch(showWalletHistory(formData));
    dispatch(showWithdrawHistory(formData));
    dispatch(getUploadImagesAdmin(formData));
  }, []);

  return (
    <>
      {/* add nft price form */}
      <Dialog
        open={nftPrice.dialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "400px" } },
        }}
        onClose={handleCloseNftPriceModal}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={1}
          >
            <Typography variant="h6">
              {nftPrice.edit ? "Update" : "Add"} Nft Price
            </Typography>
            <IconButton onClick={handleCloseNftPriceModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddUpdateNftPrice} id="nft_price">
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">Amount In INR</Typography>
              <TextField
                variant="outlined"
                size="small"
                required
                fullWidth
                type="number"
                placeholder="Amount in INR"
                value={nftPrice.priceInr}
                onChange={(e) =>
                  setNftPrice((prev) => ({ ...prev, priceInr: e.target.value }))
                }
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">Amount In Dollar</Typography>
              <TextField
                variant="outlined"
                size="small"
                required
                fullWidth
                type="number"
                placeholder="Amount in dollar"
                value={nftPrice.priceDollar}
                onChange={(e) =>
                  setNftPrice((prev) => ({
                    ...prev,
                    priceDollar: e.target.value,
                  }))
                }
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">Nft Address</Typography>
              <TextField
                variant="outlined"
                size="small"
                required
                fullWidth
                type="text"
                placeholder="Nft Address"
                value={nftPrice.nftAddress}
                onChange={(e) =>
                  setNftPrice((prev) => ({
                    ...prev,
                    nftAddress: e.target.value,
                  }))
                }
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">NFT Link</Typography>
              <TextField
                variant="outlined"
                size="small"
                required
                fullWidth
                type="text"
                placeholder="Nft Address"
                value={nftPrice.nftLink}
                onChange={(e) =>
                  setNftPrice((prev) => ({
                    ...prev,
                    nftLink: e.target.value,
                  }))
                }
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            type="submit"
            form="nft_price"
            loading={
              AddPriceToNftByAdmin_isLoading || editPriceToNftByAdmin_isLoading
            }
          >
            {nftPrice.edit ? "Update" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.dialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "400px" }, p: 2 },
        }}
        onClose={handleCloseModal}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <Stack
            sx={{ px: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Update Wallet</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle1">Amount</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              placeholder="amount"
              value={state.amnt}
              onChange={(e) => setState({ ...state, amnt: e.target.value })}
            />
            <Typography variant="subtitle1">Action</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              type="number"
              label="select action"
              value={state.action}
              sx={{
                textTransform: "capitalize",
              }}
              onChange={(e) => setState({ ...state, action: e.target.value })}
            >
              <MenuItem value="credited">Credited</MenuItem>
              <MenuItem value="debited">Debited</MenuItem>
            </TextField>
            <Typography variant="subtitle1">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              maxRows={3}
              minRows={2}
              placeholder="type remark"
              value={state.remark}
              onChange={(e) => setState({ ...state, remark: e.target.value })}
            />
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
              {updateWallet_isLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" onClick={handleUpdateWallet}>
                  Submit
                </Button>
              )}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      {/* add divident dialog */}
      <Dialog
        open={state.dividentDialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "400px" } },
        }}
        onClose={handleCloseDividentDialog}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <Stack
            sx={{ px: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Add Plan</Typography>
            <IconButton onClick={handleCloseDividentDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleUpdateDivident} id="divident-form">
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">Amount/Plan</Typography>
              <Select
                size="small"
                displayEmpty
                required
                fullWidth
                value={state.divident}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, divident: e.target.value }))
                }
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {dividentPlan?.map((item) => (
                  <MenuItem value={item.id}>{item?.name}</MenuItem>
                ))}
              </Select>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          {AddDividentByAdmin_isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" type="submit" form="divident-form">
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          minHeight: "70vh",
          width: "93%",
          m: "auto",
          backgroundColor: "#fff",
          boxShadow: 4,
          p: 2,
        }}
      >
        {/* Tabs section */}
        <Stack direction="row" alignItems="center">
          <Box
            onClick={() => setState({ ...state, tabStatus: 0 })}
            sx={{
              flex: 1,
              borderBottom:
                state.tabStatus === 0 ? "2px solid blue" : "2px solid #fff",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Profile
            </Typography>
          </Box>
          <Box
            onClick={() => setState({ ...state, tabStatus: 4 })}
            sx={{
              flex: 1,
              borderBottom:
                state.tabStatus === 4 ? "2px solid blue" : "2px solid #fff",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Plan
            </Typography>
          </Box>
          <Box
            onClick={() => setState({ ...state, tabStatus: 1 })}
            sx={{
              flex: 1,
              borderBottom:
                state.tabStatus === 1 ? "2px solid blue" : "2px solid #fff",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Referal
            </Typography>
          </Box>
          <Box
            onClick={() => setState({ ...state, tabStatus: 2 })}
            sx={{
              flex: 1,
              borderBottom:
                state.tabStatus === 2 ? "2px solid blue" : "2px solid #fff",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Withdrawl History
            </Typography>
          </Box>
          <Box
            onClick={() => setState({ ...state, tabStatus: 3 })}
            sx={{
              flex: 1,
              borderBottom:
                state.tabStatus === 3 ? "2px solid blue" : "2px solid #fff",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ textAlign: "center" }} variant="h6">
              Wallet History
            </Typography>
          </Box>
        </Stack>
        {/* Profile Section */}
        {state.tabStatus === 0 && (
          <>
            <Box sx={{ width: "40%", m: "auto", mt: 2 }}>
              <Box>
                <ShowModelText
                  firstText={"Name"}
                  secondText={showUserById_Data?.users?.name}
                />
                <ShowModelText
                  firstText={"Email"}
                  secondText={showUserById_Data?.users?.email}
                />
                <ShowModelText
                  firstText={"Mobile"}
                  secondText={showUserById_Data?.users?.mobile}
                />
                {/* wallet amount */}
                <Grid container sx={{ py: 0.5 }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}
                    >
                      Wallet Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          color: "rgba(0,0,0,1)",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        ₹{showUserById_Data?.users?.wallet?.amount}
                      </Typography>
                      <Typography
                        onClick={() =>
                          setState({
                            ...state,
                            dialog: true,
                            userId: showUserById_Data?.users?.id,
                            walletType: "wallet",
                          })
                        }
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                          color: blue[600],
                          "&:hover": {
                            color: blue[900],
                          },
                        }}
                      >
                        update
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {/* Stacking Reward */}
                <Grid container sx={{ py: 0.5 }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}
                    >
                      Stacking Reward
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          color: "rgba(0,0,0,1)",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        ₹{showUserById_Data?.users?.wallet?.reward}
                      </Typography>
                      <Typography
                        onClick={() =>
                          setState({
                            ...state,
                            dialog: true,
                            userId: showUserById_Data?.users?.id,
                            walletType: "reward",
                          })
                        }
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                          color: blue[600],
                          "&:hover": {
                            color: blue[900],
                          },
                        }}
                      >
                        update
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {/* Awareness Bonus */}
                <Grid container sx={{ py: 0.5 }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}
                    >
                      Awareness Bonus
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          color: "rgba(0,0,0,1)",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        ₹{showUserById_Data?.users?.wallet?.awareness_bonus}
                      </Typography>
                      <Typography
                        onClick={() =>
                          setState({
                            ...state,
                            dialog: true,
                            userId: showUserById_Data?.users?.id,
                            walletType: "awareness",
                          })
                        }
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                          color: blue[600],
                          "&:hover": {
                            color: blue[900],
                          },
                        }}
                      >
                        update
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {/* Field Bonus */}
                <Grid container sx={{ py: 0.5 }}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{ color: "rgba(0,0,0,0.6)", fontWeight: 500 }}
                    >
                      Field Bonus
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          color: "rgba(0,0,0,1)",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        ₹{showUserById_Data?.users?.wallet?.field_bonus}
                      </Typography>
                      <Typography
                        onClick={() =>
                          setState({
                            ...state,
                            dialog: true,
                            userId: showUserById_Data?.users?.id,
                            walletType: "field",
                          })
                        }
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                          color: blue[600],
                          "&:hover": {
                            color: blue[900],
                          },
                        }}
                      >
                        update
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {/* Add Divident Button*/}
                <Grid container sx={{ py: 0.5 }}>
                  <Grid item xs={6}>
                    <Button
                      startIcon={<Add />}
                      variant="contained"
                      size="small"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() =>
                        setState((prev) => ({ ...prev, dividentDialog: true }))
                      }
                    >
                      Add Divident Ico
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Typography fontSize="16px" fontWeight={600} my={1}>
              User Images
            </Typography>
            <Stack
              direction={"row"}
              gap={1}
              alignItems="center"
              flexWrap={"wrap"}
              sx={{ width: "100%" }}
            >
              {getUploadImagesAdmin_Data?.images?.map((item, i) => (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px 10px 0 0",
                    width: "220px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                  key={i}
                >
                  <img
                    alt="userImage"
                    src={`https://apis.artauraa.com/laravelapi/storage/app/public/${item?.path}`}
                    style={{
                      width: "100%",
                      height: "160px",
                      objectFit: "cover",
                      borderRadius: "10px 10px 0 0",
                    }}
                  />
                  {item?.img_path ? (
                    <Box sx={{ py: 1, px: 0.5 }}>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        Amount INR:{" "}
                        <span style={{ fontWeight: 600, fontSize: "14px" }}>
                          ₹{item?.img_path?.amount_inr}
                        </span>
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        Amount USD:{" "}
                        <span style={{ fontWeight: 600, fontSize: "14px" }}>
                          ${item?.img_path?.amount_usd}
                        </span>
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        NFT Address:{" "}
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            wordBreak: "break-all",
                          }}
                        >
                          {item?.img_path?.nft_address}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          wordBreak: "break-all",
                        }}
                      >
                        NFT Link:{" "}
                        <a
                          target="_blank"
                          href={item?.img_path?.nft_link}
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            marginLeft: "10px",
                          }}
                        >
                          view
                        </a>
                      </Typography>
                      <Stack direction="row" gap={2} py={1}>
                        <LoadingButton
                          loading={deleteNFTpriceAdmin_isLoading}
                          color="error"
                          variant="outlined"
                          sx={{ textTransform: "capitalize", width: "100%" }}
                          onClick={() => handleDelete(item?.img_path?.guid)}
                        >
                          Delete
                        </LoadingButton>
                        <Button
                          variant="contained"
                          sx={{ textTransform: "capitalize", width: "100%" }}
                          onClick={() =>
                            setNftPrice((prev) => ({
                              ...prev,
                              dialog: true,
                              edit: true,
                              priceInr: item?.img_path?.amount_inr,
                              priceDollar: item?.img_path?.amount_usd,
                              nftAddress: item?.img_path?.nft_address,
                              editId: item?.img_path?.guid,
                              imgPath: item?.path,
                              nftLink: item?.img_path?.nft_link,
                            }))
                          }
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Box>
                  ) : (
                    <Stack direction="row" justifyContent={"center"}>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                          width: "90%",
                          my: 1,
                          mx: "auto",
                        }}
                        onClick={() =>
                          setNftPrice((prev) => ({
                            ...prev,
                            dialog: true,
                            edit: false,
                            imgPath: item?.path,
                          }))
                        }
                      >
                        Add
                      </Button>
                    </Stack>
                  )}
                </Box>
              ))}
            </Stack>
          </>
        )}
        {/* Plan Section */}
        {state.tabStatus === 4 && (
          <Box
            sx={{
              py: 2,
            }}
          >
            <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto" }}>
              <Typography
                sx={{
                  fontSize: { xs: "17px", md: "22px" },
                  color: "rgba(0,0,0,0.5)",
                  fontWeight: 600,
                }}
              >
                Membership Plan
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Plan Type
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Amount
                  </Typography>
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Date
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Status
                  </Typography>
                </Box>
              </Stack>

              <Divider />
              {state.membershipData?.length > 0 ? (
                state.membershipData?.map((item, itemIndex) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    key={itemIndex}
                    sx={{
                      py: 1,
                      backgroundColor: "#fff",
                      boxShadow: 4,
                      my: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {membershipPlanName[Number(item?.plan_type)]}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        &#8377; {item?.amount}
                      </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {moment(item?.created_at).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {item?.status === 0
                          ? "Pending"
                          : item?.status === 1
                          ? "Purchased"
                          : item?.status === 2 && "Rejected"}
                      </Typography>
                    </Box>
                  </Stack>
                ))
              ) : (
                <Stack direction="row" justifyContent="center">
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                    }}
                  >
                    No Data Found
                  </Typography>
                </Stack>
              )}
            </Box>

            <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto", mt: 4 }}>
              <Typography
                sx={{
                  fontSize: { xs: "17px", md: "22px" },
                  color: "rgba(0,0,0,0.5)",
                  fontWeight: 600,
                }}
              >
                ICO Plan
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Plan Type
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Amount
                  </Typography>
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Date
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Status
                  </Typography>
                </Box>
              </Stack>

              <Divider />

              {state.icoData?.length > 0 ? (
                state.icoData?.map((item, itemIndex) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    key={itemIndex}
                    sx={{
                      py: 1,
                      boxShadow: 4,
                      backgroundColor: "#fff",
                      my: 1,
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {icoPlanName[Number(item?.plan_type)]}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        &#8377; {item?.amount}
                      </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {moment(item?.created_at).format("DD-MM-YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {item?.status === 0
                          ? "Pending"
                          : item?.status === 1
                          ? "Purchased"
                          : item?.status === 2 && "Rejected"}
                      </Typography>
                    </Box>
                  </Stack>
                ))
              ) : (
                <Stack direction="row" justifyContent="center">
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "20px" },
                    }}
                  >
                    No Data Found
                  </Typography>
                </Stack>
              )}
            </Box>
          </Box>
        )}
        {/* Referal Section */}
        {state.tabStatus === 1 && (
          <>
            <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto", mt: 2 }}>
              <Stack direction="row" alignItems="center">
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Name
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Email
                  </Typography>
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Mobile
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "12px", md: "20px" },
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    Action
                  </Typography>
                </Box>
              </Stack>
              <Divider sx={{ mb: 0.5 }} />
            </Box>

            {showUserReferals_Data?.users?.map((item, itemIndex) => (
              <Box
                key={itemIndex}
                sx={{ width: { xs: "98%", md: "80%" }, m: "auto", my: 1 }}
              >
                <Box sx={{ backgroundColor: "#fff", boxShadow: 4 }}>
                  <Stack direction="row" alignItems="center" sx={{ py: 0.5 }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {item?.email}
                      </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: { xs: "12px", md: "20px" },
                        }}
                      >
                        {item?.mobile}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        onClick={() => handleView(itemIndex)}
                        sx={{
                          cursor: "pointer",
                          textAlign: "center",
                          fontSize: "14px",
                          color: blue[600],
                          "&:hover": {
                            color: blue[900],
                          },
                        }}
                      >
                        view
                      </Typography>
                    </Box>
                  </Stack>

                  <Collapse
                    in={state.collapseIndex === itemIndex ? true : false}
                    sx={{ backgroundColor: "rgba(0,0,0,0.03)" }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "17px", md: "20px" },
                        ml: 3,
                        color: "rgba(0,0,0,0.5)",
                        fontWeight: 600,
                      }}
                    >
                      Plans
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        py: 1,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          Plan Name
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          Amount
                        </Typography>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          Plan Type
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          Status
                        </Typography>
                      </Box>
                    </Stack>
                    <Divider />
                    {item?.userplans?.length > 0 ? (
                      item?.userplans?.map((res, resIndex) => (
                        <Box key={resIndex}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                              py: 0.2,
                              my: 0.3,
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "17px" },
                                }}
                              >
                                {membershipPlanName[Number(res?.plan_type)]}
                              </Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "17px" },
                                }}
                              >
                                &#8377; {res?.amount}
                              </Typography>
                            </Box>

                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "17px" },
                                  textTransform: "uppercase",
                                }}
                              >
                                {res?.filter}
                              </Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <Typography
                                sx={{
                                  textAlign: "center",
                                  fontSize: { xs: "12px", md: "17px" },
                                }}
                              >
                                {res?.status === 0
                                  ? "Pending"
                                  : res?.status === 1
                                  ? "Purchased"
                                  : res?.status === 2 && "Rejected"}
                              </Typography>
                            </Box>
                          </Stack>
                          <Divider sx={{ color: "#fff" }} />
                        </Box>
                      ))
                    ) : (
                      <Stack direction="row" justifyContent="center">
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          No Data Found
                        </Typography>
                      </Stack>
                    )}
                  </Collapse>
                </Box>
              </Box>
            ))}
          </>
        )}
        {state.tabStatus === 3 && (
          <>
            <Box>
              <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto", mt: 2 }}>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Amount
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Action
                    </Typography>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Date
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: "12px", md: "20px" },
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Remark
                    </Typography>
                  </Box>
                </Stack>
                <Divider sx={{ mb: 0.5 }} />
                {showWalletHistory_Data?.users?.map((item, itemIndex) => (
                  <Box
                    key={itemIndex}
                    sx={{
                      backgroundColor: "#fff",
                      boxShadow: 2,
                      py: 0.6,
                      my: 0.7,
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          &#8377; {item?.amount}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          color={
                            item?.action === "credited" ? "green" : "error"
                          }
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                            textTransform: "capitalize",
                          }}
                        >
                          {item?.action}
                        </Typography>
                      </Box>

                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: { xs: "12px", md: "20px" },
                          }}
                        >
                          {item?.remark}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
        {state.tabStatus === 2 && (
          <Box sx={{ width: { xs: "98%", md: "80%" }, m: "auto", mt: 2 }}>
            <Stack direction="row">
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardHeaderText>Date</UserCardHeaderText>
              </Stack>
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardHeaderText>Amount</UserCardHeaderText>
              </Stack>
              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardHeaderText>Accout No.</UserCardHeaderText>
              </Stack>

              <Stack sx={{ flex: 1 }} direction="row" justifyContent={"center"}>
                <UserCardHeaderText>Status</UserCardHeaderText>
              </Stack>
            </Stack>
            {showWithdrawHistory_isLoading ? (
              <CircularProgress />
            ) : (
              showWithdrawHistory_Data?.users?.map((item, ItemIndex) => (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    boxShadow: 3,
                    p: 1,
                    borderRadius: 1,
                    my: 1.2,
                  }}
                  key={ItemIndex}
                >
                  <Stack direction="row" sx={{ mt: 1 }}>
                    <Stack
                      sx={{ flex: 1 }}
                      direction="row"
                      justifyContent={"center"}
                    >
                      <UserCardText sx={{ textTransform: "capitalize" }}>
                        {moment(item?.updated_at).format("DD-MM-YYYY")}
                      </UserCardText>
                    </Stack>
                    <Stack
                      sx={{ flex: 1 }}
                      direction="row"
                      justifyContent={"center"}
                    >
                      <UserCardText>&#8377; {item?.amount}</UserCardText>
                    </Stack>
                    <Stack
                      sx={{ flex: 1 }}
                      direction="row"
                      justifyContent={"center"}
                    >
                      <UserCardText>{item?.account_no}</UserCardText>
                    </Stack>
                    <Stack
                      sx={{ flex: 1 }}
                      direction="row"
                      justifyContent={"center"}
                    >
                      <Box>
                        {item?.status === 0 && (
                          <Stack direction="row" alignItems="center" gap={1}>
                            <Box
                              sx={{
                                backgroundColor: yellow[700],
                                px: 1,
                                py: 0.3,
                                borderRadius: 4,
                              }}
                            >
                              <p style={{ color: "#fff" }}>Pending</p>
                            </Box>
                            {/* <MoreVertIcon sx={{ color: "#000" }} /> */}
                          </Stack>
                        )}
                        {item?.status === 1 && (
                          <Box
                            sx={{
                              backgroundColor: green[700],
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 4,
                            }}
                          >
                            <p style={{ color: "#fff" }}>Approved</p>
                          </Box>
                        )}
                        {item?.status === 2 && (
                          <Box
                            sx={{
                              backgroundColor: red[500],

                              px: 1.5,
                              py: 0.5,
                              borderRadius: 4,
                            }}
                          >
                            <p style={{ color: "#fff" }}>Rejected</p>
                          </Box>
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              ))
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserDetail;
